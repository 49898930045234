<template>
  <div id="main">
    <el-main>
      <div class="section">
        <div class="header">
          <div class="handle">报名信息表</div>
          <div class="btnBox">
            <el-button @click="gotochangeInfo">修改信息</el-button>
            <el-button @click="$router.replace({ name: 'examlist' })"
              >返回首页</el-button
            >
          </div>
        </div>
        <div class="infoBox">
          <div style="color: #e0823d; margin: 10px 0px">
            (提示：个人信息需如实填写)
          </div>
          <!-- 个人信息 -->
          <div class="selfInfo">
            <h3>个人信息</h3>
            <div class="box">
              <div class="left-item">
                <div class="item">
                  <!--<div>
                    <span>用户名：</span
                    ><span>{{ username ? username : "-" }}</span>
                  </div>-->
                  <div>
                    <span>姓名：</span
                    ><span>{{ selfInfo.name ? selfInfo.name : "-" }}</span>
                  </div>
                  <div>
                    <span>政治面貌：</span
                    ><span>{{
                      selfInfo.political_status
                        ? selfInfo.political_status
                        : "-"
                    }}</span>
                  </div>
                  <div>
                    <span>民族：</span
                    ><span>{{ selfInfo.nation ? selfInfo.nation : "-" }}</span>
                  </div>
                  <div>
                    <span>科类：</span
                    ><span v-if="selfInfo.category == 1">文科</span
                    ><span v-else-if="selfInfo.category == 2">理科</span
                    ><span v-else-if="selfInfo.category == 3">{{ checkedSub }}</span
                    ><span v-else>-</span>
                  </div>
                  <div>
                    <span>邮箱：</span
                    ><span>{{ selfInfo.email ? selfInfo.email : "-" }}</span>
                  </div>
                  <!--<div>
                    <span>家庭住址：</span
                    ><span>{{
                      jtProvinceName + jtCityName + selfInfo.address
                        ? jtProvinceName + jtCityName + selfInfo.address
                        : "-"
                    }}</span>
                  </div>-->
                </div>
                <div class="item">
                  <div>
                    <span>性别：</span>
                    <span v-if="selfInfo.gender === 0 || selfInfo.gender === '0'">男</span>
                    <span v-else-if="selfInfo.gender == 1">女</span>
                    <span v-else>-</span>
                  </div>
                  <div>
                    <span>出生日期：</span
                    ><span>{{
                      selfInfo.birthday ? selfInfo.birthday : "-"
                    }}</span>
                  </div>
                  <div>
                    <span>籍贯：</span
                    ><span>{{ selfInfo.jiguan ? selfInfo.jiguan : "-" }}</span>
                  </div>
                  <div>
                    <span>手机号：</span
                    ><span>{{ selfInfo.phone ? selfInfo.phone : "-" }}</span>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <span>证件号码：</span
                    ><span>{{ sfzNum ? sfzNum : "-" }}</span>
                  </div>
                  <div>
                    <span>入学年份：</span
                    ><span>{{
                      selfInfo.start_school_date
                        ? selfInfo.start_school_date
                        : "-"
                    }}</span>
                  </div>
                  <div>
                    <span>生源地：</span
                    ><span>{{
                      selfInfo.shengyuandi ? selfInfo.shengyuandi : "-"
                    }}</span>
                  </div>
                  <div>
                    <span>外语语种：</span
                    ><span>
                      {{
                        selfInfo.foreign_language
                          ? selfInfo.foreign_language
                          : "-"
                      }}</span
                    >
                  </div>
                </div>
                <div class="item">
                  <div>
                    <span>家庭住址：</span
                    ><span>{{
                      jtProvinceName + jtCityName + selfInfo.address
                        ? jtProvinceName + jtCityName + selfInfo.address
                        : "-"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="right-item">
                <img
                  v-if="selfInfo.picture_path && selfInfo.picture_path !== ''"
                  style="width: 137px; height: 208px"
                  :src="selfInfo.picture_path + '?=' + Date.parse(new Date())"
                />
                <img
                  v-else
                  src="../../../assets/images/defaultAvatar.png"
                  style="width:137px; height: 208px"
                />
              </div>
            </div>
          </div>
          <!-- 家庭信息 -->
          <div class="familyInfo">
            <h3>家庭信息</h3>
            <table cellspacing="0">
              <tr>
                <th>姓名</th>
                <th>成员信息</th>
                <th>职业</th>
                <th>工作单位</th>
                <th>职务</th>
                <th>文化程度</th>
                <th>联系电话</th>
              </tr>
              <tr v-for="(item, index) in selfInfo.familyinfo" :key="index">
                <td>{{ item.name == "" ? "-" : item.name }}</td>
                <td>{{ item.relation == "" ? "-" : item.relation }}</td>
                <td>{{ item.career == "" ? "-" : item.career }}</td>
                <td>{{ item.workplace == "" ? "-" : item.workplace }}</td>
                <td>{{ item.job == "" ? "-" : item.job }}</td>
                <td>
                  {{ item.educationdegree == "" ? "-" : item.educationdegree }}
                </td>
                <td>{{ item.phone == "" ? "-" : item.phone }}</td>
              </tr>
            </table>
          </div>
          <!-- 学校信息 -->
          <div class="schoolInfo">
            <h3>学校信息</h3>
            <table cellspacing="0">
              <tr>
                <th>所在学校</th>
                <th>中学联系人</th>
                <th>中学联系电话</th>
                <th>中学详细地址</th>
                <th>教导处联系电话</th>
                <th>中学邮政编码</th>
              </tr>
              <tr>
                <td>
                  {{
                    selfInfo.schoolinfo.name == ""
                      ? "-"
                      : selfInfo.schoolinfo.name
                  }}
                </td>
                <td>
                  {{
                    selfInfo.schoolinfo.contactpeople == ""
                      ? "-"
                      : selfInfo.schoolinfo.contactpeople
                  }}
                </td>
                <td>
                  {{
                    selfInfo.schoolinfo.contactphone == ""
                      ? "-"
                      : selfInfo.schoolinfo.contactphone
                  }}
                </td>
                <td>
                  {{
                    selfInfo.schoolinfo.address == ""
                      ? "-"
                      : selfInfo.schoolinfo.address
                  }}
                </td>
                <td>
                  {{
                    selfInfo.schoolinfo.jdcphone == ""
                      ? "-"
                      : selfInfo.schoolinfo.jdcphone
                  }}
                </td>
                <td>
                  {{
                    selfInfo.schoolinfo.postcode == ""
                      ? "-"
                      : selfInfo.schoolinfo.postcode
                  }}
                </td>
              </tr>
            </table>
          </div>
          <!-- 学习成绩 -->
          <div class="grad">
            <h3>学习成绩</h3>
            <table cellspacing="0">
              <tr>
                <th></th>
                <th>
                  <div>语文</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>数学</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>英语</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>物理</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>化学</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>生物</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>政治</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>历史</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>地理</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>文理(综合)</div>
                  <div>(得分/总分)</div>
                </th>
                <th>
                  <div>年级排名</div>
                  <div>(得分/总分)</div>
                </th>
              </tr>
              <tr
                v-for="(item, index) in selfInfo.scoreinfo"
                :key="'scoreinfo-' + index"
              >
                <td>{{ item.grade }}</td>
                <td
                  v-for="(items, indexs) in item.scoreItems"
                  :key="'scoreItems-' + indexs"
                >
                  <span>{{ items.myscore }}</span
                  >/<span>{{ items.totalscore }}</span>
                </td>
              </tr>
            </table>
          </div>
          <!-- 志愿情况 -->
          <div class="wash">
            <h3>志愿情况</h3>
<!--            <div class="wash-item">-->
<!--              <h4>强基计划志愿</h4>-->
<!--              <table cellspacing="0">-->
<!--                <tr>-->
<!--                  <th style="width: 33.3%;">第一志愿</th>-->
<!--                  <th style="width: 33.3%;">第二志愿</th>-->
<!--                  <th style="width: 33.3%;">第三志愿</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td-->
<!--                    v-for="(item, index) in selfInfo.qjwishinfo"-->
<!--                    :key="'qjwishinfo-' + index"-->
<!--                  >-->
<!--                    {{ item.zyunivname }} - {{ item.zy1name }}-->
<!--                    {{ item.zy2name }} {{ item.zy3name }}-->
<!--                  </td>-->
<!--                </tr>-->
<!--              </table>-->
<!--            </div>-->
            <div class="wash-item">
              <h4>国内高校志愿</h4>
              <table cellspacing="0">
                <tr>
                  <th style="width: 20%;">第一志愿</th>
                  <th style="width: 20%;">第二志愿</th>
                  <th style="width: 20%;">第三志愿</th>
                  <th style="width: 20%;">第四志愿</th>
                  <th style="width: 20%;">第五志愿</th>
                </tr>
                <tr></tr>
                <tr>
                  <td
                    v-for="(item, index) in selfInfo.wishinfo"
                    :key="'wishinfo-' + index"
                  >
                    {{ item.zyunivname }} - {{ item.zy1name }}
                    {{ item.zy2name }} {{ item.zy3name }}
                  </td>
                  <template v-if="selfInfo.wishinfo.length == 3">
                    <td>-</td>
                    <td>-</td>
                  </template>
                  <template v-if="selfInfo.wishinfo.length == 4">
                    <td>-</td>
                  </template>
                </tr>
              </table>
            </div>
            <div class="wash-item">
              <h4>中外合作办学高校志愿</h4>
              <table cellspacing="0">
                <tr>
                  <th style="width: 50%;">第一志愿</th>
                  <th style="width: 50%;">第二志愿</th>
                </tr>
                <tr>
                  <td
                    v-for="(item, index) in selfInfo.coopwishinfo"
                    :key="'coopwishinfo-' + index"
                  >
                    {{ item.zyunivname }} - {{ item.zy1name }}
                    {{ item.zy2name }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div style="width:100%;text-align:center">
          <el-button
            @click="gotochangeInfo"
            style="width:200px;height:50px;font-size:16px;margin-top:30px"
          >
            修改信息
          </el-button>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {
  getCityList,
  getProvinceList,
  getSubjects,
  getStep1,
} from "r/index/signUp";

export default {
  data() {
    return {
      provinceData: {}, //接口获取的全国省份数据
      cityData: {}, //接口获取的全国城市数据
      jtProvinceName: "", //家庭住址省份名称
      jtCityName: "", //家庭住址城市名称
      examUuid: "",
      username: "", //用户名
      sfzNum: "", //证件号码
      selfInfo: {
        //个人信息
        name: "", //学生姓名
        phone: "", //手机号
        sex: 0, //性别 0男  1女
        email: "", //邮箱
        jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
        shengyuandi_code: undefined, //生源code 【省份id+城市id】
        birthday: "", //出生日期，类似20020624
        nation: "", //民族
        political_status: "", //政治面貌
        start_school_date: "", //入学年份
        category: "", //科类[文理科 1 文科 2 理科 3 选科]
        gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
        foreign_language: "", //语种
        address_code: "", //家庭住址省市code【省份id+城市id】
        address: "", //家庭住址
        familyinfo: [
          //家庭信息
          {
            career: "",
            educationdegree: "",
            job: "",
            name: "",
            phone: "",
            relation: "",
            workplace: "",
          },
          {
            career: "",
            educationdegree: "",
            job: "",
            name: "",
            phone: "",
            relation: "",
            workplace: "",
          },
        ],
        schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
        schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
        school: "", //学校code（id）通过【获取所有学校列表】获得
        schoolinfo: {
          //学校信息
          contactpeople: "",
          contactphone: "",
          address: "",
          jdcphone: "",
          postcode: "",
          province: "",
          city: "",
          name: "",
        },
        scoreinfo: [
          //成绩信息
          {
            grade: "高一上(期末)",
            gradeIndex: 0,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高一下(期末)",
            gradeIndex: 1,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高二上(期末)",
            gradeIndex: 2,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高二下(期末)",
            gradeIndex: 3,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高三上(期末)",
            gradeIndex: 4,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "最后大考",
            gradeIndex: 5,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
        ],
        wishinfo: [
          //国内志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
        ],
        coopwishinfo: [
          //国外志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
          },
        ],
        qjwishinfo: [
          //强基计划志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
        ],
      },
      checkedSubject: [], //选中的科目id数组
      checkedSub: "", // 选科
    };
  },
  created() {
    this.sfzNum = window.localStorage.getItem("sfzNum");
    this.username = window.localStorage.getItem("username");
    this.examUuid = window.localStorage.getItem("exam_uuid");
    this.provinceData = JSON.parse(window.localStorage.getItem("provinceData"));
    this.cityData = JSON.parse(window.localStorage.getItem("cityData"));
    if (
      this.provinceData == null ||
      !this.provinceData ||
      Object.values(this.provinceData).length == 0
    ) {
      this.getProvinceLists(); //获取全部省份
    }
    if (
      this.cityData == null ||
      !this.cityData ||
      Object.values(this.cityData).length == 0
    ) {
      this.getCityLists(); //获取全国城市
    }
    this.getAccountList();
  },
  methods: {
    //获取个人信息
    getAccountList() {
      getStep1()
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.selfInfo = res.data.info;
            if (!res.data.info.familyinfo) {
              this.selfInfo.familyinfo = [
                //家庭信息
                {
                  career: "",
                  educationdegree: "",
                  job: "",
                  name: "",
                  phone: "",
                  relation: "",
                  workplace: "",
                },
                {
                  career: "",
                  educationdegree: "",
                  job: "",
                  name: "",
                  phone: "",
                  relation: "",
                  workplace: "",
                },
              ];
            }
            if (res.data.info.category && res.data.info.category == "0") {
              //科类
              this.selfInfo.category = "";
            } else if (res.data.info.category && res.data.info.category == 3) {
              if (
                res.data.info.gk_selectsubject == "" ||
                !res.data.info.gk_selectsubject
              ) {
                this.checkedSubject = [];
              } else {
                this.checkedSubject = res.data.info.gk_selectsubject.split("#");
              }
            }
            this.getSubjectsList();
            if (!res.data.info.schoolinfo) {
              this.selfInfo.schoolinfo = {
                //学校信息
                contactpeople: "",
                contactphone: "",
                address: "",
                jdcphone: "",
                postcode: "",
                province: "",
                city: "",
                name: "",
              };
            }
            if (!res.data.info.scoreinfo) {
              this.selfInfo.scoreinfo = [
                //成绩信息
                {
                  grade: "高一上(期末)",
                  gradeIndex: 0,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高一下(期末)",
                  gradeIndex: 1,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高二上(期末)",
                  gradeIndex: 2,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高二下(期末)",
                  gradeIndex: 3,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高三上(期末)",
                  gradeIndex: 4,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "最后大考",
                  gradeIndex: 5,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
              ];
            }
            if (!res.data.info.wishinfo) {
              this.selfInfo.wishinfo = [
                //国内志愿
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
              ];
            }
            if (!res.data.info.coopwishinfo) {
              this.selfInfo.coopwishinfo = [
                //国外志愿
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                },
              ];
            }
            if (!res.data.info.qjwishinfo) {
              this.selfInfo.qjwishinfo = [
                //强基计划志愿
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
              ];
            }
            if (!res.data.info.start_school_date) {
              this.selfInfo.start_school_date = "";
            }
            console.log(res.data)
            let provinceId;
            if(res.data.info.address_code){
               provinceId = Number(res.data.info.address_code.toString().substring(0, 2));
               this.jtProvinceName = this.provinceData[provinceId].name; //前2位取省份
               this.jtCityName = this.cityData[Number(res.data.info.address_code.toString().substring(2, 6))].name; //后4位取城市
            }else{
              this.jtProvinceName = "";
              this.jtCityName = "";
            }
            if(!this.selfInfo.address){
              this.selfInfo.address = "";
            }
            if(this.selfInfo.gender === "" || this.selfInfo.gender===null){
              this.selfInfo.gender = "";
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // if (this.examUuid && this.examUuid !== "") {
      //
      // } else {
      //   this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
      // }
    },
    //获取全国省份
    getProvinceLists() {
      getProvinceList()
        .then((res) => {
          if (res.data.code === 200) {
            this.provinceData = res.data.list;
            window.localStorage.setItem(
              "provinceData",
              JSON.stringify(res.data.list)
            );
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取全国城市
    getCityLists() {
      getCityList()
        .then((res) => {
          if (res.data.code === 200) {
            this.cityData = res.data.list;
            window.localStorage.setItem(
              "cityData",
              JSON.stringify(res.data.list)
            );
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    gotochangeInfo() {
      this.$router.push({
        name: "changepersonalinfo",
      });
    },
    //获取学科列表--选科使用
    getSubjectsList() {
      getSubjects()
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            let resData = res.data.list;
            let resArr = [];
            Object.keys(resData).forEach((key) => {
              let obj = {
                id: key,
                name: resData[key],
              };
              resArr.push(obj);
            });
            resArr.forEach((item1) => {
              this.checkedSubject.forEach((item2) => {
                if (item1["id"] == item2) {
                  this.checkedSub += item1["name"] + " ";
                }
              });
            });
            console.log(this.subjectsList);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  min-height: 80%;
  overflow: auto;
  .el-main {
    width: 1124px;
    margin: 26px auto;
    padding: 0 50px;
  }

  .section {
    height: auto;
    background: #fff;
    margin: auto;
    padding: 50px;
    overflow: scroll;
    .header {
      position: relative;
      font-size: 32px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #333333;
      .handle {
        text-align: center;
      }
      .btnBox {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        vertical-align: center;
        height: 50px;
        line-height: 37px;
      }
    }
    .infoBox {
      .selfInfo {
        border-bottom: 1px solid #333333;
        padding-bottom: 30px;
        .box {
          display: flex;
          .left-item {
            position: relative;
            flex: 3;
            display: flex;
            justify-content: space-between;
            .item {
              &:nth-child(1){
                flex: 1.2;
              }
              &:nth-child(2){
                flex: 1;
              }
              &:nth-child(2){
                flex: 1;
              }
              &:nth-child(4){
                position: absolute;
                top: 145px;
              }
              div {
                margin-top: 10px;
              }
            }
          }
          .right-item {
            flex: 1;
            text-align: center;
          }
        }
      }
      .familyInfo {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .schoolInfo {
        border-bottom: 1px solid #333333;
        padding-bottom: 30px;
      }
      .grad {
        padding-bottom: 30px;
        padding-top: 20px;
        border-bottom: 1px solid #333333;
      }
    }
  }
  table {
    border: 1px solid #ccc;
    margin-top: 20px;
    width: 100%;
    tr {
      width: 100%;
      padding: 10px 0;
      th {
        padding: 10px 0;
        background: #fafafa;
        border: 1px solid #ccc;
      }
      td {
        padding: 10px 0;
        border: 1px solid #ccc;
        text-align: center;
      }
    }
  }
}
::v-deep .el-button {
  width: 90px;
  height: 30px;
  background: #563279;
  color: #fff;
  text-align: center;
  font-size: 12px;
  padding: 0;
}
</style>
